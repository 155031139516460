import { render, staticRenderFns } from "./MusicAIBottom.vue?vue&type=template&id=2daaa006&scoped=true"
import script from "./MusicAIBottom.vue?vue&type=script&lang=js"
export * from "./MusicAIBottom.vue?vue&type=script&lang=js"
import style0 from "./MusicAIBottom.vue?vue&type=style&index=0&id=2daaa006&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2daaa006",
  null
  
)

export default component.exports