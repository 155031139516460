<template>
  <div class="fybody">
    <!--============    左侧大列表    ============================== -->
    <div class="fybody_left">
      <!--    选择声音-->
      <div class="fy_first">
        <div class="first_left">
          <div class="first_left_one" @click="dialogVoice = true">
            <i class="el-icon-plus icon-bold" ></i>
          </div>
          <div class="first_left_two">
            <div class="first_left_two_up">Select a voice</div>
            <div class="first_left_two_down">Browse hundreds of royalty free  voices</div>
          </div>
        </div>
        <div class="first_right">
          <img class="right_img" src="@/assets/images/ai/select_artist.png" alt="" />
        </div>
      </div>
      <!--    上传音频-->
      <div class="fy_second">
        <div class="sec_lef">
          <el-upload
              class="upload-demo"
              :action="uploadUrl"
              name="name"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              multiple
              :limit="1"
              >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>



          <div class="sec_lef_up">
            <div class="sec_lef_up_le">
              <div class="sec_lef_up_le_da">Upload Audio</div>
              <div class="sec_lef_up_le_xiao">Or Drag And Drop Mp3/Wav File Here</div>
            </div>
            <div class="sec_lef_up_ri">
              <i class="el-icon-upload2" style="font-size: 50px"></i>
            </div>
          </div>
          <div class="sec_lef_down">
            <div class="sec_lef_down_box">
              <i class="el-icon-plus" style="font-size: 30px"></i>
            </div>
          </div>
        </div>
        <!--      <div class="sec_lef"></div>-->
      </div>
      <!--    选择移除的东西-->
      <div class="fy_third">
        <div class="third_le">
          <div class="third_le_nei"><i class="el-icon-orange" style="margin-right: 10px"></i>Remove Instrumentals</div>
        </div>
        <div class="third_le third_ri">
          <div class="third_le_nei"><i class="el-icon-mic" style="margin-right: 10px"></i>Remove Reverb/Echo</div>
        </div>
      </div>
      <!--    滑动调整参数-->
      <!--    <div class="fy_fourth"> </div>-->
      <!--    按钮-->
      <div class="fy_fiveth">
        <el-button @click="createMusicfy()" class="gradient-btn">立即生成</el-button>
      </div>
    </div>

    <!--============    右侧大列表    ==============================-->
    <div class="fybody_right">
      <el-scrollbar class="right_list"  wrap-style="overflow-x:hidden;">
        <div class="right_list_m" v-for="(item,index) in tableData" :key="index">
          <div class="m_box">

            {{item.id}}

          </div>



        </div>


      </el-scrollbar>



    </div>

    <!--============    弹窗 选择声音    ========================-->
    <el-dialog title="" :visible.sync="dialogVoice" width="70%" center :before-close="handleClose">
      <div class="voictype">
        <div class="voictype_one">Parody</div>
        <div class="voictype_one">Royalty Free</div>
        <div class="voictype_one">Instrumentals</div>
      </div>
      <div class="dialog_voice">
        <div class="dialog_voice_box" v-for="(item,index) in voiceData" :key="index">
          <div >{{item.ids}}</div>
        </div>
      </div>
      <div class="pagecss">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size = "limit"
            layout="total, prev, pager, next"
            :total="voiceTotal">
        </el-pagination>
      </div>


      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVoice = false">取 消</el-button>
        <el-button type="primary" @click="chooseVoice()">确 定</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import MusicAiBottom from "@/components/content/MusicAIBottom";
import PopupCommon from "@/components/popup/PopupCommon.vue"
import {
  musicfyGetVoice,
  getLiked,
  getLiking,
  musicAIGet,
  musicAIMake, musicfyVoiceDataBase, musicfyCreate,
} from "@/api/Mine";
export default {
  name: "Musicfy",
  components: {
  },
  data() {
    return {
      uploadUrl: "http://www.music.com/api/musicfyUpload",
      voiceReq:{},
      voiceTotal:0,
      page:1,
      limit:20,
      voiceData:{},
      tableData:[{'id':1},{'id':2},{'id':3},{'id':4},{'id':5},{'id':6},{'id':7},{'id':8},{'id':9},{'id':10},],
      dialogVoice: false,
    }
  },
  created() {
    this.musicfyGetVoicefunc(); //获取声音模板
  },
  methods: {
    //文件上传
    handleAvatarSuccess(res, file) {
      console.log(file)
      this.ruleForm.image = URL.createObjectURL(file.raw);
      this.image1 = res.data.url
      console.log(this.ruleForm.image,this.image1,'process.env.VUE_APP_FILE_PATH')
    },
    // 立即生成按钮
    createMusicfy(){

      musicfyCreate().then((res) => {
        this.voiceTotal = res.data
      }).catch((err) => {
        console.log(err, '生成失败');
      });
    },
    handleCurrentChange(val) {
      this.page = val
      this.musicfyGetVoicefunc();
    },
    //获取声音模板
    musicfyGetVoicefunc(type='parody'){
      let req = {'type':type,'page':this.page,'limit':this.limit}
      musicfyGetVoice(req).then((res) => {
        this.voiceTotal = res.data.total
        this.voiceData = res.data.data
      }).catch((err) => {
        console.log(err, '声音模板失败');
      });
    },
    chooseVoice(){
      this.dialogVoice = false
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    }
  },
}
</script>

<style scoped  lang='less'>
//选择声音弹窗
.voictype {
  display: flex;
  align-items: center;
  justify-content: center;
  .voictype_one {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 50px;
    background-color: #06D5BA;
    margin: 0px 10px 10px 10px;
  }

}
.dialog_voice {
  display: flex;
  flex-wrap:wrap;

  .dialog_voice_box{
    display: flex;
    width: 100px;
    height: 100px;
    background-color: #06D5BA;
    margin: 5px;
  }
}
.pagecss {
  display: flex;
  align-items: center;
  justify-content: center;

}


.fybody {
  display: flex;
  flex-direction: row;
  width: 100%;
  .fybody_left {
    display: flex;
    width: 60%;
    flex-direction: column;
    color: @headerSelectTextColor  !important;

  }
  .fybody_right {
    margin-left: 15px;
    display: flex;
    width: 40%;
    flex-direction: column;
  }
}

.right_list {
  display: flex;
  flex-direction: column;
  width:100%;
  height: 550px;
  overflow-x: hidden;
  margin-top: 20px;
  border-radius: 10px;
  color: @headerSelectTextColor  !important;
  .right_list_m {
    display: flex;
    .m_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      margin-bottom: 10px;
      margin-right: 10px;
      border-radius: 10px;
      width: 100%;
      background-color: @recommendMusicianListBGColor;
      height: 100px;

    }

  }
}

.fy_first {
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  background-color: @recommendMusicianListBGColor;
  padding: 10px 0 10px 10px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
  .first_left {
    display: flex;
    align-items: center;
    .first_left_one {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed @headerSelectTextColor ;
      cursor: pointer;
      height: 80px;
      width: 90px;
      border-radius: 0.666667rem;
    }
    .first_left_two {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding:8px 10px;
      .first_left_two_up {
        display: flex;
        padding:8px 0;
        font-size: 30px;
        line-height: 1.1;
        font-weight: 600;
      }
      .first_left_two_down {
        display: flex;
        padding:8px 0;
      }
    }
  }
  .first_right {
    display: flex;
    //background-color: #8d974d;
    width: 350px;
    .right_img {
      max-width: 100%;
      position: absolute;
      right: 0px;
      bottom: 0;
      z-index: 30;
      height: 133%;
      width: auto;
      display: block;
      color: transparent;
    }


  }
}
.fy_second {
  display: flex;
  margin:10px 0;
  border-radius: 15px;
  padding: 10px;
  background-color: @recommendMusicianListBGColor;
  .sec_lef {
    display: flex;
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 15px;
    border: 2px dashed @headerSelectTextColor  ;
    padding: 10px;
    cursor: pointer;
    .sec_lef_up {
      display: flex;
      flex: 1;
      border-bottom: 2px solid @headerSelectTextColor;
      .sec_lef_up_le {
        display: flex;
        flex-direction: column;
        flex: 3;
        padding: 10px;
        .sec_lef_up_le_da {
          flex: 1;
          font-size: 26px;
          font-weight: 500;
          padding: 5px;

        }
        .sec_lef_up_le_xiao {
          flex: 1;
          font-size: 17px;
          font-weight: 300;
          padding: 5px;

        }
      }
      .sec_lef_up_ri {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
    .sec_lef_down {
      display: flex;
      flex: 1;
      padding: 10px 10px 0 10px;
      .sec_lef_down_box {
        display: flex;
        align-items: center;
        justify-content: center;
        border:2px solid @headerSelectTextColor;
        border-radius: 10px;
        width: 50px;
        height: 50px;
      }
    }
  }

}
.fy_third {
  display: flex;
  height: 100px;
  margin:10px 0;
  .third_le {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: @recommendMusicianListBGColor;
    border-radius: 15px;
    border: 2px solid @headerSelectTextColor;
    cursor: pointer;
    .third_le_nei {
      display: flex;
    }

  }
  .third_ri {
    margin-left: 20px;
  }

}
.fy_fourth {
  display: flex;
  height: 150px;
  margin:10px 0;
  border-radius: 15px;
  padding: 10px 0 10px 40px;
  background-color: @recommendMusicianListBGColor;
}
.fy_fiveth {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:10px 0;
  border-radius: 15px;
  padding: 10px 0 10px 40px;
    .gradient-btn {
      background-image: linear-gradient(to right, #e6717d 0%, #b656f0 100%);
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;
      width: 300px;
      margin: 4px 2px;
      cursor: pointer;
      transition: background-image 0.5s ease;
    }

    .gradient-btn:hover {
      background-image: linear-gradient(to right, #b656f0 0%, #e6717d 100%);
    }

}


</style>